<template>
  <v-dialog
    max-width="50%"
    @click:outside="$store.commit('setVideoCallViewer', false)"
    v-model="getVideoCallViewer"
  >
    <v-card class="pt-2">
      <v-card-text class="black--text">
        <v-list subheader two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-numeric-1-circle </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-justify black--text text-h5">
              Please use this Video Call feature for question related to the
              forms.
            </v-list-item-content>
            <v-list-item-action>
              <!-- <v-btn @click="openImage()" color="secondary">
                <v-icon>mdi-map-marker</v-icon>
                Main Kiosk
              </v-btn> -->
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-numeric-2-circle </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-justify black--text text-h5">
              For other questions related to county building and service
              provided, please go to the Main Kiosk.
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="openImage()" color="secondary" width="200" x-large>
                <v-icon>mdi-map-marker</v-icon>
                Main Kiosk
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          @click="initiateCall()"
          color="secondary"
          class="mr-3"
          width="200"
          x-large
        >
          <v-icon class="pr-2">mdi-video</v-icon>
          Continue
        </v-btn>
        <v-btn
          @click="$store.commit('setVideoCallViewer', false)"
          color="secondary"
          outlined
          width="200"
          x-large
        >
          <v-icon class="pr-2">mdi-close</v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "videoCallViewer",
  data() {
    return {
      count: 0,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["getVideoCallViewer", "getKioskProfile", "getKioskProfile"]),
  },
  methods: {
    getAvailableUsers(value) {
      return value.id;
    },
    initiateCall() {
      this.$store.dispatch("clearResetTimeout")
      this.$store.dispatch('getAssociatedGroups')
    },
    openImage() {
      this.$store.dispatch("openImageViewer", {
        name: "Main Kiosk",
        url: "https://cms.ottawa.arsconnect.com/uploads/22_main_kiosk_f06fb9fc7f.gif",
      });
      this.$store.commit("setTouchRequest", {
        module: "Image Viewer",
        action: "Show Map",
        response: "Main Kiosk",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
};
</script>
