import * as fb from "@/firebase/index.js";
import crypto from "crypto";
import axios from 'axios';
export default {
    state: {
        associatedGroups: [],
        videoCall: {
            formCols: 10,
            teleCol: 2,
            startVideo: false,
            meetingId: null,
            meetingCode: null
        },
        callListener: null,
        callEndTimer: 0,
        callObj: null
    },
    getters: {
        getAssociatedGroups: state => state.associatedGroups,
        getVideoCall: state => state.videoCall,
        getCallListener: state => state.callListener,
        getCallObj: state => state.callObj
    },
    mutations: {
        setAssociatedGroups(state, groupName) {
            state.associatedGroups.push(groupName)
        },
        setVideoCall(state, callObj) {
            state.videoCall = callObj
        },
        setCallObj(state, obj) {
            state.callObj = obj
        }
    },
    actions: {
        getAssociatedGroups({ getters, commit, dispatch }) {
            console.log('Action Called')
            fb.userGroupsCollection
                .where("kiosk", "array-contains", getters.getKioskProfile.data().name)
                .get().then(groups => {
                    groups.forEach(element => {
                        commit('setAssociatedGroups', element.data().name)
                    });
                    if (getters.getAssociatedGroups.length > 0) {
                        dispatch('getAvailableUsers', getters.getAssociatedGroups)
                    } else {
                        commit("setVideoCallViewer", false);
                        dispatch(
                            "avatarSpeak",
                            "No Groups are associated to the kiosk. Please visit Clerks Office."
                        );
                    }
                })
        },

        getAvailableUsers({ dispatch, commit }, groups) {
            fb.userCollection.where("groups", "array-contains-any", groups)
                .where("onlineStatus", "==", "Available").where("onCall", "==", false).get()
                .then(users => {
                    let availableUsers = users.docs.map(users => users.id)
                    if (availableUsers.length > 0) {
                        dispatch('startCall', availableUsers)
                    } else {
                        commit("setVideoCallViewer", false);
                        dispatch(
                            "avatarSpeak",
                            "Please try again after sometime."
                        );
                    }
                })
        },

        startCall({ getters, commit, state, dispatch }, usersList) {
            let callObj = {
                callStatus: "Initiated",
                meetingCode: crypto.randomBytes(50).toString("hex").slice(0, 20),
                startTime: new Date(),
                endTime: null,
                conferenceAttendees: [],
                callerInfo: getters.getKioskProfile.data(),
                callInitiator: { name: getters.getKioskProfile.data().name, id: getters.getKioskProfile.id },
                callType: 'Pull',
                requestedUser: usersList,
                calledUser: usersList,
                availableUsers: usersList,
                source: true
            };

            // Add the callObj to start a video call with available Users

            fb.telepresenceCollection.add(callObj).then(response => {
                state.callEndTimer = setTimeout(() => {
                    commit("setVideoCallViewer", false);
                    fb.telepresenceCollection.doc(response.id).update({
                        callStatus: "notAnswered",
                        endTime: new Date(),
                        requestedUser: null
                    });
                    getters.getCallListener();
                    dispatch(
                        "avatarSpeak",
                        "Please try again after sometime."
                    );
                }, 35000);
                dispatch('declineCloudFunction', response.id)
                dispatch('handleCall', response.id)
            })
        },

        declineCloudFunction({ commit }, id) {
            const config = {
                method: 'post',
                url: 'https://us-central1-ottawa-dashboard.cloudfunctions.net/apiV2/getCallInfo/a763cd056f1b2405788443b7197e0708',
                params: { call: id, action: 'get' }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },
        handleCall({ commit, state, getters, dispatch }, callId) {
            state.callListener = fb.telepresenceCollection.doc(callId).onSnapshot(
                call => {
                    console.log('Outgoing Call Tracking', call.data())
                    switch (call.data().callStatus) {
                        case 'Initiated':
                            commit("setVideoCallViewer", false);
                            commit("setTextBubbleText", "Calling....")
                            break;
                        case 'inProgress':
                            commit('setCallObj', call)
                            clearTimeout(state.callEndTimer)
                            commit('setVideoCall', {
                                formCols: 9,
                                teleCol: 3,
                                startVideo: true,
                                meetingId: callId,
                                meetingCode: call.data().meetingCode
                            })
                            break;
                        case 'Declined':
                            dispatch(
                                "avatarSpeak",
                                "All requested users declined the call. Please try again after sometime."
                            );
                            clearTimeout(state.callEndTimer)
                            commit('setVideoCall', {
                                formCols: 10,
                                teleCol: 2,
                                startVideo: false,
                                meetingId: null,
                                meetingCode: null
                            })
                            getters.getCallListener();
                            break;
                        case 'Ended':
                            break;
                        default:
                            console.log('Hello There !!!!')
                    }
                }
            )
        },

        handleEndCall({ getters }) {
            console.log('Requested Users: ', getters.getCallObj.data().requestedUser)
            if (getters.getCallObj.data().requestedUser === null) {
                fb.telepresenceCollection.doc(getters.getCallObj.id).update({
                    callStatus: 'Ended',
                    requestedUser: null
                })
            } else {
                setTimeout(function () {
                    fb.telepresenceCollection.doc(getters.getCallObj.id).update({
                        source: false
                    })
                }, 500)
            }
        }


    }
}
