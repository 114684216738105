<template>
  <v-row justify="center" class="fill-height pl-3">
    <!-- <v-col cols="1" align="start">
      <v-btn>
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col> -->
    <v-col :cols="getVideoCall.formCols" class="pl-3 pr-0">
      <v-card flat class="containerBorder" :height="iframeHeight">
        <v-card-title class="pa-0">
          <v-row no-gutters>
            <v-col cols="1" align="center" class="pt-5">
              <v-btn fab color="secondary" @click="reload()">
                <v-icon large>mdi-home</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="11" class="pt-4">
              <iframe src="https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/" width="99%" :height="iframeHeight - 40"
                id="myframe"></iframe>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col :cols="getVideoCall.teleCol" align="center" justify="center">
      <v-row class="fill-height pr-3" v-if="!getVideoCall.startVideo">
        <v-col cols="12" align-self="end">
          <v-card width="285" @click="$store.commit('setVideoCallViewer', true); startSession(); logInfo()" color="secondary">
            <v-img src="@/assets/videoCall.jpg" height="150" width="285"></v-img>
            <v-card-text class="white--text text-h5">
              Talk to Live Person
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" align-self="center">
          <v-card width="285" color="grey lighten-3">
            <v-card-title class="justify-center"> Next Steps </v-card-title>
            <v-list subheader two-line color="blue-grey lighten-5">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon> mdi-receipt </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-justify">
                  Bring the receipt emailed to you.
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon> mdi-map-marker </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-justify">
                  Go to the Clerk's Office. Only paper copies of documents is
                  REQUIRED. No digital copies will be accepted.
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions class="justify-center secondary" @click="openImage()">
              <v-icon x-large color="white">mdi-map-marker</v-icon>
              <h2 class="white--text pl-3">Clerk's Office</h2>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="fill-height">
        <telepresence></telepresence>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import telepresence from "@/components/telepresence";
export default {
  name: "menuMatrix",
  components: {
    telepresence,
  },
  computed: {
    ...mapGetters([
      "getTouchCount",
      "isUserLooking",
      "getKioskProfile",
      "getVideoCall",
      "getGreetingStatus",
    ]),
    iframeHeight() {
      console.log("Window Inner height", window.innerHeight - 180);
      return window.innerHeight - 200;
    },
  },
  watch: {
    getGreetingStatus(newVal) {
      if (!newVal) {
        document.getElementById("myframe").src =
          document.getElementById("myframe").src;
      }
    },
  },
  methods: {
    reload() {
      document.getElementById("myframe").src =
        document.getElementById("myframe").src;
    },
    openImage() {
      this.startSession()
      this.$store.dispatch("openImageViewer", {
        name: "Clerk's Office",
        url: "https://cms.ottawa.arsconnect.com/uploads/22_clerk_register_of_deeds_170d998c37.gif",
      });
      this.$store.commit("setTouchRequest", {
        module: "Default",
        action: "Touch Detected",
        response: "User Session Started - Touch Detected",
        timeStamp: new Date(),
        requestType: "Session",
      });

      this.$store.commit("setTouchRequest", {
        module: "Image Viewer",
        action: "Show Map",
        response: "Clerk's Office",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    startSession() {
      if (!this.getGreetingStatus) {
        this.$store.commit("setGreetingStatus", true);
        this.$store.dispatch("resetKiosk");
      }
    },
    logInfo(){
      this.$store.commit("setTouchRequest", {
            module: "Default",
            action: "Touch Detected",
            response: "User Session Started - Touch Detected",
            timeStamp: new Date(),
            requestType: "Session",
          });

          this.$store.commit("setTouchRequest", {
            module: "Telepresence",
            action: "Telepresence Confirmation",
            response: "Dialog Open",
            timeStamp: new Date(),
            requestType: "Touch",
          });
    }
  },
  mounted() {
    window.addEventListener("blur", () => {
      setTimeout(() => {
        if (document.activeElement.tagName === "IFRAME") {
          this.startSession
          this.$store.commit("setTouchRequest", {
            module: "Default",
            action: "Touch Detected",
            response: "User Session Started - Touch Detected",
            timeStamp: new Date(),
            requestType: "Session",
          });

          this.$store.commit("setTouchRequest", {
            module: "IFrame",
            action: "Clicked on Form",
            response: "Clicked on Form",
            timeStamp: new Date(),
            requestType: "Touch",
          });
        }
      });
    });
  },
};
</script>

<style>
.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #9f2b00 !important;
  border-radius: 0px !important;
  margin-bottom: 10px;
}
</style>

