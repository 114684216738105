<template>
  <v-dialog
    v-model="getImageViewer.show"
    width="65%"
    @click:outside="$store.commit('closeImageViewer')"
  >
    <v-card width="100%">
      <v-row no-gutters>
        <v-col align="center">
          <v-card-text class="text-h4 pl-16 font-weight-bold">{{
            getImageViewer.name
          }}</v-card-text>
        </v-col>
        <v-col cols="1" align="center">
          <v-card-text class="d-flex justify-end" style="overflow-y: hidden">
            <v-icon large color="error" @click="$store.commit('closeImageViewer')"> mdi-close </v-icon>
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-img :src="getImageViewer.url"></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "imageViewer",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getImageViewer"]),
  },
};
</script>

<style scoped>
</style>
