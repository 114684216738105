<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="12">
      <menuMatrix></menuMatrix>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar.vue";
import menuMatrix from "@/components/menuMatrix.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    avatar,
    menuMatrix,
  },
  computed: {
    ...mapGetters(["appDimensions"]),
    avatarWidth() {
      return this.appDimensions.width / 2;
    },
  },
  methods: {
    goBack() {
      document.getElementById('iframe').contentWindow.location.reload();
    },
  },
};
</script>

