<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12" align="center" justify="center">
      <div class="video-panel" id="video-panel"></div>
    </v-col>
    <v-col cols="12" align="center" justify="center">
      <v-btn color="secondary" @click="endCall()" x-large>
        <v-icon class="pa-2">mdi-phone-hangup</v-icon>
        End Call
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import * as fb from "@/firebase/index.js";
export default {
  name: "telepresence",
  computed: {
    ...mapGetters([
      "getKioskProfile",
      "isUserLooking",
      "getGreetingStatus",
      "getVideoCall",
      "getCallListener",
    ]),
  },
  data() {
    return {
      api: null,
      callEndTimeout: 0,
    };
  },
  methods: {
    endCall() {
      // this.$store.commit("setVideoCall", {
      //   formCols: 10,
      //   teleCol: 2,
      //   startVideo: false,
      //   meetingId: null,
      //   meetingCode: null,
      // });
      // this.getCallListener();
      setTimeout(() => {
        this.$store.dispatch("handleEndCall").then(() => {
          console.log("End Call is being called");
          this.$store.commit(
            "setTextBubbleText",
            "Hi, my name is " +
              this.getKioskProfile.data().avatarName +
              ", Please select an option below to begin filling out your license online."
          );
          this.api.executeCommand("hangup");
          this.$store.commit("setVideoCall", {
            formCols: 10,
            teleCol: 2,
            startVideo: false,
            meetingId: null,
            meetingCode: null,
          });
          this.getCallListener();
        });
      }, 1000);

      clearTimeout(this.callEndTimeout);
      this.$store.commit("setTouchRequest", {
        module: "Telepresence",
        action: "Stop Telepresence",
        response: "Telepresence ended by User",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },

  mounted() {
    const domain = "meet.arsconnect.com/" + this.getVideoCall.meetingCode;
    const options = {
      roomName: this.getVideoCall.meetingCode,
      width: 400,
      height: window.innerHeight - 350,
      parentNode: document.querySelector("#video-panel"),
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        TOOLBAR_BUTTONS: [],
        TOOLBAR_ALWAYS_VISIBLE: false,
        HIDE_INVITE_MORE_HEADER: true,
        DISABLE_VIDEO_BACKGROUND: true,
        DEFAULT_BACKGROUND: "#FFF",
      },
      userInfo: {
        email: "",
        displayName: this.getKioskProfile.data().name,
        setTileView: true,
      },
    };
    window.JitsiMeetExternalAPI =
      window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
    this.api = new window.JitsiMeetExternalAPI(domain, options);
    this.api.addListener("participantLeft", () => {
      this.noOfParticipants = this.api.getNumberOfParticipants();
      console.log("NO OF PARTICIPANTS:", this.noOfParticipants);
      if (this.noOfParticipants === 1) {
        this.endCall();
      }
    });

    this.api.addListener("participantJoined", () => {
      this.noOfParticipants = this.api.getNumberOfParticipants();
      console.log("Total number of participant", this.noOfParticipants);
      if (this.noOfParticipants >= 1) {
        clearTimeout(this.callEndTimeout);
        console.log("Total number of participant speech deleted");
        this.$store.commit("clearTextBubbleText");
      }
    });
    console.log(this.api);

    // this.callEndTimeout = setTimeout(() => {
    //   const that = this;
    //   this.$store.commit("setDefaultBubbleText", true);
    //   that.$store.dispatch(
    //     "avatarSpeak",
    //     "Please try to call again after sometime."
    //   );
    //   clearTimeout(this.callEndTimeout);
    //   this.$store.commit("setFormCols", 10);
    //   this.$store.commit("setTeleCols", 2);
    //   this.$store.commit("setStartVideo", false);
    //   this.$store.commit("setTouchRequest", {
    //     module: "Telepresence",
    //     action: "Stop Telepresence",
    //     response: "Call not answered",
    //     timeStamp: new Date(),
    //     requestType: "Touch",
    //   });
    // }, 25000);
  },
  // beforeDestroy () {
  //     this.$root.$el.parentNode.removeChild(this.$root.$el)
  // },
};
</script>
