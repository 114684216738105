import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from "@/firebase";
import Home from '../views/Home.vue'
import login from '@/views/login.vue'
import loadData from '@/views/loadData.vue'
import recognition from '@/components/recognition.vue';
import store from '@/store';
Vue.use(VueRouter)

const routes = [
  {
    path: '/loadData',
    name: 'loadData',
    component: loadData,
    meta:{
      requiresAuth: true,
    },
  },
  {
    path: '/rec',
    name: 'recognition',
    component: recognition,
    meta:{
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('How many times you are coming to the router')
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const requireDataLoaded = to.matched.some(x => x.meta.requireDataLoaded);

  if(requiresAuth && !auth.currentUser){
    next('/login');
  }
  else if(requireDataLoaded && !store.getters.getDataLoaded){
    next('/loadData');
  }
  else{
    next();
  }
})

export default router
