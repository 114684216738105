<template>
  <v-row no-gutters >
    <v-col class="mr-12" v-if="showAvatar" cols="1">
      <avatar :initialWidth="270"></avatar>
    </v-col>
    <v-col>
      <v-card
        class="bubbleBorder px-2"
        height="180"
        max-height="180"
        color="rgba(30, 101, 39, 0.88)"
      >
        <v-row no-gutters class="fill-height ma-0 pa-0">
          <v-col class="px-5" align="center" align-self="center">
            <span
              :class="{ 'text-h4': getTextBubbleText.length >= 200 }"
              class="white--text text-h3 font-weight-medium pl-5"
            >
              {{ getTextBubbleText }}
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar";
import { mapGetters } from "vuex";

export default {
  name: "appHeader",
  components: {
    avatar,
  },
  data() {
    return {
      showAvatar: false,
      changeFont: false,
    };
  },
  computed: {
    ...mapGetters([
      "getTextBubbleText",
      "getIsSpeaking",
      "getQnaStatus",
      "getTouchCount",
      "isUserLooking",
    ]),
  },
  watch: {
    $route(to, from) {
      console.log("ROUTE TO", to.path);
      console.log("ROUTE FROM", from.path);
      if (to.path === "/") {
        this.showAvatar = true;
      } else {
        this.showAvatar = false;
      }
    }
  }
};
</script>

<style scoped>
.bubbleBorder {
  border-radius: 0 0 30px 30px !important;
}
</style>

