<template>
  <v-app>
    <appHeader v-if="$router.currentRoute.path !== '/login'"></appHeader>
    <v-main>
      <v-fade-transition leave-absolute>
        <router-view />
      </v-fade-transition>
    </v-main>
    <session-end-viewer></session-end-viewer>
    <video-call-viewer></video-call-viewer>
    <image-viewer></image-viewer>
    <recognition></recognition>
  </v-app>
</template>

<script>
import appHeader from "@/components/appHeader.vue";
import SessionEndViewer from "./components/dialogs/sessionEndViewer.vue";
import recognition from "@/components/recognition.vue";
import { mapGetters } from "vuex";
import VideoCallViewer from "./components/dialogs/videoCallViewer.vue";
import ImageViewer from "./components/dialogs/imageViewer.vue";

export default {
  name: "App",
  data() {
    return {
      count: 0,
    };
  },
  components: {
    appHeader,
    recognition,
    SessionEndViewer,
    VideoCallViewer,
    ImageViewer,
  },
  computed: {
    ...mapGetters([
      "appDimensions",
      "getKioskId",
      "getStartUserSession",
      "getTouchCount",
      "isUserLooking",
    ]),
  },
  created() {
    window.onclick = () => {
      this.$store.commit("setTouchCount", this.getTouchCount + 1);
      this.$store.commit("setStartUserSession", new Date());
      if (this.getTouchCount === 1) {
        console.log("Touch Count", this.getTouchCount);
      } else if (this.getTouchCount !== 0) {
        this.$store.commit("setTouchCount", this.getTouchCount + 1);
      }
      if (this.getTouchCount > 1 && !this.isUserLooking) {
        this.$store.dispatch("clearResetTimeout").then(() => {
          this.$store.dispatch("resetKiosk");
        });
      }
    }
  
},
mounted() {
  window["console"]["log"] = function () { };
  console.warn = function () { };
},
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444;
}

.v-virtual-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444;
  width: 10px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444;
}

.v-dialog {
  margin-top: 100px;
}
</style>
