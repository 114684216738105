import { db } from "@/firebase"
export default {
    state: {
        contentFetched: false,
        appDimensions: {},
        sessionEndViewer: false,
        videoCallViewer: false,
        imageViewer: {},
        // user session handling (Displayed on Dashboard App)
        startUserSession: undefined,
        endUserSession: undefined,
        userQnaRequest: [],
        touchRequest: [],
        userSession: {},
        meetingId: null,
        // Telepresnce


    },
    getters: {
        contentFetched: state => state.contentFetched,
        appDimensions: state => state.appDimensions,
        getStartUserSession: state => state.startUserSession,
        getEndUserSession: state => state.endUserSession,
        getTouchRequest: state => state.touchRequest,
        getUserQnaRequest: state => state.userQnaRequest,
        getSessionEndViewer: state => state.sessionEndViewer,
        getVideoCallViewer: state => state.videoCallViewer,
        getImageViewer: state => state.imageViewer,
    },
    mutations: {
        setAppDimensions(state) {
            let newWidht = window.innerWidth;
            //let newHeight = (1080 / 1920) * window.innerWidth;
            let newHeight = window.innerHeight;
            //proto: (original height / original width) x new width = new height
            // console.log(`New App Dimensions: (w X h) : (${newWidht} X ${newHeight})`);
            state.appDimensions = {
                width: newWidht,
                height: newHeight
            }
        },
        setPageHeader(state, value) {
            state.pageHeader = value;
        },
        setStartUserSession(state, startUserSession) {
            state.startUserSession = startUserSession
        },
        setEndUserSession(state, endUserSession) {
            state.endUserSession = endUserSession
        },
        setTouchRequest(state, touchRequest) {
            state.touchRequest.push(touchRequest)
        },
        setUserQnaRequest(state, userQnaRequest) {
            state.userQnaRequest.push(userQnaRequest)
        },
        setSessionEndViewer(state, status) {
            state.sessionEndViewer = status
        },
        setVideoCallViewer(state, status) {
            state.videoCallViewer = status
        },
        setImageViewer(state, imgObj) {
            state.imageViewer = imgObj
        },
        closeImageViewer(state) {
            state.imageViewer = { show: false }
        }
    },
    actions: {
        updateUserSession({ getters, state }) {
            db.collection('userSession').add({
                kioskID: getters.getKioskId,
                userSessionStartTime: getters.getStartUserSession,
                userSessionEndTime: getters.getEndUserSession,
                userSessionLogs: JSON.stringify(getters.getTouchRequest),
            }).then(() => {
                state.touchRequest = []
                state.userQnaRequest = []
            })
        },
        openImageViewer({ commit }, imgObj) {
            imgObj.show = true
            commit('setImageViewer', imgObj)
        },
    }
}
